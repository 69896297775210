import "./comingSoon.css";

export default function ComingSoon() {
  return (
    <div id="comingsoon">
      <div className="comingsoon">
        <div className="comingsoon-404">
          <h3>Coming</h3>
          <h1>
            <span>S</span>
            <span>o</span>
            <span>o</span>
            <span>n</span>
          </h1>
        </div>
        <h2>Stay Tuned! New Page Are Coming!</h2>
      </div>
    </div>
  );
}
