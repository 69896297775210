import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import NotFound from "./components/NotFound";
import ComingSoon from "./components/ComingSoon";
/** page **/
import Erc20 from "./page/erc20/scan";
import Sol20 from "./page/sol20/scan";
import Sender from "./page/sender/index";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Router>
    <React.StrictMode>
      <Header />
      <main className="flex-shrink-0 my-5">
        <div className="container">
          <Routes>
            <Route path="/" element={<ComingSoon />} />
            <Route path="/Batch/Erc20" element={<Erc20 />} />
            <Route path="/Batch/Trc20" element={<ComingSoon />} />
            <Route path="/Batch/Solana" element={<Sol20 />} />
            <Route path="/SendBalance" element={<Sender />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </main>
      <Footer />
    </React.StrictMode>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
