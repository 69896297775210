export default function UploadFile(props: any) {
  return (
    <div className="my-2 text-center">
      <label
        htmlFor={props.label.toString().replace(" ", "")}
        className="form-label"
      >
        {props.label}
      </label>
      <input
        onChange={props.handelForm}
        className="form-control"
        type="file"
        id={props.label.toString().replace(" ", "")}
      />
    </div>
  );
}
