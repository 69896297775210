export default function Footer() {
  return (
    <footer className="footer mt-auto py-3">
      <ul className="nav justify-content-center border-bottom pb-3 mb-3">
        <li className="nav-item">
          <a href="!#" className="nav-link px-2 text-muted">
            Home
          </a>
        </li>
        <li className="nav-item">
          <a href="!#" className="nav-link px-2 text-muted">
            Crypto
          </a>
        </li>
        <li className="nav-item">
          <a href="!#" className="nav-link px-2 text-muted">
            Tools
          </a>
        </li>
        <li className="nav-item">
          <a href="!#" className="nav-link px-2 text-muted">
            Blog
          </a>
        </li>
        <li className="nav-item">
          <a href="!#" className="nav-link px-2 text-muted">
            About
          </a>
        </li>
      </ul>
      <p className="text-center text-muted">&copy; 2022 Company, Inc</p>
    </footer>
  );
}
