export default function TextArea(props: any) {
  return (
    <div className="form-floating my-2">
      <textarea
        value={props.value}
        onChange={props.handelForm}
        className="form-control"
        placeholder="Input Here..."
        id={props.label.toString().replace(" ", "")}
        style={{ height: "300px" }}
      ></textarea>
      <label htmlFor={props.label.toString().replace(" ", "")}>
        {props.label}
      </label>
    </div>
  );
}
