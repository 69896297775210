export default function SelectForm(props: any) {
  return (
    <div className="form-floating my-2">
      <select
        onChange={props.handelForm}
        value={props.value}
        className="form-select"
        id={props.label.toString().replace(" ", "")}
      >
        {props.list.map((val: any, key: any) => (
          <option key={key} value={val.value}>
            {val.label}{" "}
          </option>
        ))}
      </select>
      <label htmlFor={props.label.toString().replace(" ", "")}>
        {props.label}
      </label>
    </div>
  );
}
