import React from "react";

TableForm.defaultProps = {
  isBalance: false,
};

export default function TableForm(props: any) {
  let jj = 0;
  return (
    <div style={{ maxHeight: "400px" }} className="table-responsive mt-2">
      <table className="table table-sm caption-top">
        <caption>Result scan...</caption>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Address</th>
            <th scope="col">Balance</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {props.addressAll.map((val: any, num: number) => (
            <React.Fragment>
              {" "}
              {props.isBalance && Number(val.balance) > 0 ? (
                <tr
                  key={num + 1}
                  className={
                    val.status === "success" ? "table-success" : "table-danger"
                  }
                >
                  <th scope="row">{(jj = jj + 1)}</th>
                  <td>{val.address}</td>
                  <td style={{ whiteSpace: "nowrap" }}>{val.balance}</td>
                  <td>
                    <b>{val.status.toUpperCase()}</b>
                  </td>
                </tr>
              ) : (
                <React.Fragment>
                  {!props.isBalance && (
                    <tr
                      key={num + 1}
                      className={
                        val.status === "success"
                          ? "table-success"
                          : "table-danger"
                      }
                    >
                      <th scope="row">{(jj = jj + 1)}</th>
                      <td>{val.address}</td>
                      <td style={{ whiteSpace: "nowrap" }}>{val.balance}</td>
                      <td>
                        <b>{val.status.toUpperCase()}</b>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              )}{" "}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}
