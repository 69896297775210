import React from "react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import type { LinkProps } from "react-router-dom";
import { Display } from "./display";

function CustomLink({ children, to, ...props }: LinkProps) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });
  return (
    <Link to={to} className={match ? "nav-link active" : "nav-link"} {...props}>
      {children}
    </Link>
  );
}

export default function index() {
  return (
    <header>
      <Navbar
        bg="dark"
        expand="md"
        variant="dark"
        fixed="top"
        style={{ backgroundColor: "#262626" }}
      >
        <Container fluid>
          <Navbar.Brand href="/">Si Mbah</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Item>
                <CustomLink to="/">Home</CustomLink>
              </Nav.Item>
              <Nav.Item>
                <CustomLink to="/SendBalance">Multi Sender</CustomLink>
              </Nav.Item>
              <Nav.Link href="#link">Link</Nav.Link>
              <NavDropdown
                active={Display() ? true : false}
                title="Batch Balance"
                id="basic-nav-dropdown"
              >
                <Link to="/Batch/Erc20" className="dropdown-item">
                  Erc20
                </Link>
                <Link to="/Batch/Trc20" className="dropdown-item">
                  Trc20
                </Link>
                <Link to="/Batch/Solana" className="dropdown-item">
                  Solana
                </Link>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}
