import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import FormInput from "../../components/FormInput";
import SelectForm from "../../components/SelectForm";
import TextArea from "../../components/TextArea";
import ChecksForm from "../../components/ChecksForm";
import UploadFile from "../../components/UploadFile";
import ListChainId from "./chains.json";
import TableForm from "./table";
import PQueue from "p-queue";
import Ads from "../../components/Ads";
import { Connection, PublicKey, LAMPORTS_PER_SOL } from "@solana/web3.js";
import { getAccount, getMint } from "@solana/spl-token";
import { ArrayXlsx } from "../../components/ArrayXlsx";

const queue = new PQueue({ concurrency: 7 });

const listRpc = [
  {
    label: "Choose Rpc",
    value: "choose",
  },
  {
    label: "Solana",
    value: "https://solana-mainnet.phantom.tech/",
  },
  {
    label: "Solana 1",
    value: "https://api.mainnet-beta.solana.com/",
  },
];

const GetNameChainId = (id: any) => {
  let isi = JSON.parse(JSON.stringify(ListChainId));
  for (let i = 0; i <= isi.length; i++) {
    if (isi[i]) {
      if (Number(id) === Number(isi[i].chainId)) {
        return isi[i].chain;
      }
    }
  }
};

const ShowError = (error: any, url: string = "#") => {
  if (error.reason) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.reason,
      footer:
        '<a href="' + url + '" target="_blank">Why do I have this issue?</a>',
    });
  } else if (error.message) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
      footer:
        '<a href="' + url + '" target="_blank">Why do I have this issue?</a>',
    });
  } else {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong",
      footer:
        '<a href="' + url + '" target="_blank">Why do I have this issue?</a>',
    });
  }
};

export default function Scan() {
  const [isCustomUrl, setIsCustomUrl] = useState(false);
  const [urlRpc, setUrlRpc] = useState("choose");
  const [tokenAddress, setTokenAddress] = useState("");
  const [listAddress, setListAddress] = useState("");
  const [zeroBalance, setZeroBalance] = useState(false);
  const [sameAddress, setsameAddress] = useState(false);
  const [isChainId, setIsChainId] = useState("");
  const [tokenName, setTokenName] = useState("");
  const [supplyToken, setSupplyToken] = useState("");
  const [decimalToken, setDecimalToken] = useState<number>(0);
  const [balanceAll, setBalanceAll] = useState<number>(0);
  const [resultScan, setResultScan] = useState<
    Array<{ address: string; balance: string; status: string }>
  >([]);
  const [isConnect, setIsConnect] = useState(false);
  const [addressScan, setAddressScan] = useState("");

  useEffect(() => {
    //window.scrollTo(0, 0);
    document.title = "Multi Scan Address";
    HandelReloadTextInLocalStorage();
    /**
    const db = localStorage.getItem("FileWalletSolana");
    if (db) {
      console.log(db);
      setListAddress(db);
    }**/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CheckConnect = async (url: string) => {
    try {
      new Connection(url);
      setIsConnect(true);
    } catch (error: any) {
      ShowError(error, "https://chainlist.org/");
    }
  };
  const CheckValidAddressContract = async (sc: string) => {
    try {
      const provider = new Connection(urlRpc);
      const tokens = new PublicKey(sc);
      const detail = await provider.getTokenSupply(tokens);
      await setDecimalToken(await detail.value.decimals);
      await setSupplyToken(await detail.value.uiAmount!.toString());
    } catch (error: any) {
      ShowError(error, "https://links.ethers.org/v5-errors-CALL_EXCEPTION");
    }
  };

  const HandelUrlRpc = (e: any) => {
    setIsChainId("");
    const { nodeName, value } = e.target;
    if (value === "custom" && nodeName === "SELECT") {
      setIsCustomUrl(true);
    } else if (value === "exit" && nodeName === "INPUT") {
      setIsCustomUrl(false);
    }
    setUrlRpc(value);
    const regexpression =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
    if (regexpression.test(value)) {
      setTimeout(() => {
        CheckConnect(value);
      }, 2000);
    }
  };
  const HandelTokenAddress = (e: any) => {
    const { value } = e.target;
    setTokenAddress(value);
    if (value) {
      setTimeout(() => {
        CheckValidAddressContract(value);
      }, 1000);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Invalid Address Smart Contract Tokens",
      });
    }
  };
  const HandelRemoveSameAddress = () => {
    let address = listAddress.trim();
    let address2 = address.split("\n");
    let address3 = Array.from(new Set(address2));
    let address4 = address3.join("\n");
    localStorage.setItem("FileWalletSolana", address4);
    setListAddress(address4);
  };
  const HandelListAddress = (e: any) => {
    const { value } = e.target;
    setListAddress(value);
    localStorage.setItem("FileWalletSolana", value);
  };
  const HandelZeroBalance = (e: any) => {
    setZeroBalance(e.target.checked);
  };
  const HandelsameAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    setsameAddress(e.target.checked);
    if (e.target.checked) {
      HandelRemoveSameAddress();
    }
  };
  const HandelReloadTextInLocalStorage = () => {
    const isi = localStorage.getItem("FileWalletSolana");
    if (isi) {
      setListAddress(isi);
    }
  };
  const HandelUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      e.preventDefault();
      if (!e.target.files) return;
      let reader: FileReader = new FileReader();
      reader.onload = async (event: Event) => {
        if (!reader.result) return;
        let tval = reader.result.toString();
        localStorage.setItem("FileWalletSolana", tval);
        HandelReloadTextInLocalStorage();
      };
      reader.readAsText(e.target.files[0]!);
    } catch (error: any) {
      ShowError(error);
    }
  };
  const ScanAddress = async () => {
    setBalanceAll(0);
    setResultScan([]);
    let jumlah = 0;
    const address1 = listAddress.trim();
    const address2 = address1.split("\n");
    const provider = new Connection(urlRpc);
    let list: Array<{ address: string; balance: string; status: string }> = [];
    const token = tokenAddress;
    if (token) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "The token balance scan feature is not yet available.",
      });
      return;
    }
    address2.forEach(async (val: any, num: number) => {
      await queue.add(async () => {
        try {
          setAddressScan(val);
          let balance, blns, addr;
          if (val) {
            if (token) {
              balance = 100;
            } else {
              addr = new PublicKey(val);
              balance = await provider.getBalance(addr);
              blns = Number(balance) / Number(LAMPORTS_PER_SOL);
            }
            blns = blns ? blns : "0.0";
            const success = {
              address: val,
              balance: blns.toString(),
              status: "success",
            };
            list.push(success);
            jumlah += Number(balance.toString());
            console.log(jumlah);
            setBalanceAll(Number(jumlah));
          } else {
            const error = {
              address: val,
              balance: "0",
              status: "error",
            };
            list.push(error);
          }
        } catch (e) {
          const error = {
            address: val,
            balance: "0",
            status: "error",
          };
          list.push(error);
        }
        await setResultScan(list);
      });
    });
  };
  const Download = () => {
    ArrayXlsx(resultScan, "Solana");
  };
  return (
    <div className="row">
      <div className="col-sm-12 col-md-12">
        <div className="row g-2">
          <div className="col-md">
            {isCustomUrl === true ? (
              <FormInput
                handelForm={HandelUrlRpc}
                value={urlRpc}
                label="Custom Url Rpc"
              />
            ) : (
              <SelectForm
                list={listRpc}
                handelForm={HandelUrlRpc}
                value={urlRpc}
                label="List Rpc"
              />
            )}
          </div>
          <div className="col-md">
            <FormInput
              read={isConnect ? false : true}
              handelForm={HandelTokenAddress}
              value={tokenAddress}
              label="Address Token"
            />
          </div>
        </div>
        {isConnect && (
          <div className="text-center">
            <div className="d-grid gap-2 mx-auto">
              <button
                className="btn btn-outline-success"
                type="button"
                disabled={true}
              >
                Connect with : Solana
              </button>
              {isConnect && supplyToken && (
                <button
                  className="btn btn-outline-success"
                  type="button"
                  disabled={true}
                >
                  Supply Token :{supplyToken}
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      <Ads />
      <div className="col-sm-12 col-md-12">
        <TextArea
          handelForm={HandelListAddress}
          value={listAddress}
          label={
            listAddress
              ? "List Address ( " +
                listAddress.split("\n").length.toString() +
                " )"
              : "List Address"
          }
        />
        <div className="row mt-4">
          <div className="col-sm-6 col-md-6">
            <UploadFile
              handelForm={HandelUploadFile}
              label="Uploud File List Address"
            />
          </div>
          <div className="col-sm-6 col-md-6">
            <ChecksForm
              handelForm={HandelsameAddress}
              value={sameAddress}
              label="Remove same Address"
            />
            <ChecksForm
              handelForm={HandelZeroBalance}
              value={zeroBalance}
              label="Hidden Zero Balance"
            />
          </div>
          <Ads />
          <div className="col-sm-12 col-md-12 my-1">
            <div className="d-grid gap-2">
              <button
                onClick={ScanAddress}
                className={
                  isConnect && listAddress
                    ? "btn btn-success"
                    : "btn btn-danger"
                }
                type="button"
                disabled={
                  isConnect && listAddress
                    ? resultScan.length > 0
                      ? resultScan.length === listAddress.split("\n").length
                        ? false
                        : true
                      : false
                    : true
                }
              >
                {resultScan.length > 0
                  ? resultScan.length === listAddress.split("\n").length
                    ? "Completed!!!"
                    : "Loading!!!"
                  : "Scan.."}
              </button>
              <button
                onClick={Download}
                className="btn btn-primary"
                type="button"
                disabled={resultScan.length > 0 ? false : true}
              >
                Export xlsx
              </button>
            </div>
          </div>
          {resultScan.length > 0 && (
            <div className="col-sm-12 col-md-12 mt-5">
              <div className="d-grid gap-2 mx-auto text-center">
                <div>
                  <b>Total Balance</b>
                </div>
                <div>
                  {tokenAddress
                    ? Number(balanceAll) / Math.pow(10, Number(decimalToken))
                    : Number(balanceAll) / Number(LAMPORTS_PER_SOL)}
                  {" - "}
                  {tokenAddress ? "Token" : "SOL"}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-sm-6 col-md-6 mx-auto my-1">
                  <div className="text-center">
                    <b>Progress</b>
                  </div>
                  <div className="progress my-2">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: `${
                          (
                            (Number(resultScan.length) /
                              Number(listAddress.split("\n").length)) *
                            100
                          ).toString() + "%"
                        }`,
                      }}
                      aria-valuenow={
                        (Number(resultScan.length) /
                          Number(listAddress.split("\n").length)) *
                        100
                      }
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      {(Number(resultScan.length) /
                        Number(listAddress.split("\n").length)) *
                        100}
                      {"%"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <p style={{ fontSize: "9px" }}>{addressScan}</p>
              </div>
              <TableForm addressAll={resultScan} isBalance={zeroBalance} />
            </div>
          )}
          <Ads />
        </div>
      </div>
    </div>
  );
}
