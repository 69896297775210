export default function CheckForm(props: any) {
  return (
    <div className="form-check form-switch my-2">
      <input
        checked={props.value}
        onChange={props.handelForm}
        className="form-check-input"
        type="checkbox"
        role="switch"
        id={props.label.toString().replace(" ", "")}
      />
      <label
        className="form-check-label"
        htmlFor={props.label.toString().replace(" ", "")}
      >
        {props.label}
      </label>
    </div>
  );
}
