import React from "react";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import Connect from "./connect";
import Ads from "../../components/Ads";
import Ads2 from "../../components/Ads2";

function getLibrary(provider: any) {
  return new Web3Provider(provider);
}

export default function Index() {
  const isMetaMask = (window as any).ethereum;

  return (
    <div className="row">
      {isMetaMask ? (
        <Web3ReactProvider getLibrary={getLibrary}>
          <Connect />
        </Web3ReactProvider>
      ) : (
        <React.Fragment>
          <Ads2 />
          <div className="col-sm-12 col-md-12 text-center my-5">
            <div>
              <h2
                style={{
                  top: "50%",
                  fontFamily: "Montserrat, sans-serif",
                  position: "relative",
                  color: "#262626",
                  textTransform: "uppercase",
                  fontSize: "50px",
                  letterSpacing: "3px",
                }}
              >
                Please Install Metamask
              </h2>
            </div>
          </div>
          <Ads />
        </React.Fragment>
      )}
    </div>
  );
}
