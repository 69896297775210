FormInput.defaultProps = {
  read: false,
};

export default function FormInput(props: any) {
  return (
    <div className="form-floating my-2">
      <input
        readOnly={props.read}
        onChange={props.handelForm}
        value={props.value}
        type="text"
        className="form-control"
        id={props.label.toString().replace(" ", "")}
        placeholder="Input Here..."
      />
      <label htmlFor={props.label.toString().replace(" ", "")}>
        {props.label}
      </label>
    </div>
  );
}
