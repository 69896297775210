import React, { useState, useEffect, useRef } from "react";
import { ethers } from "ethers";
import Swal from "sweetalert2";
import FormInput from "../../components/FormInput";
import SelectForm from "../../components/SelectForm";
import TextArea from "../../components/TextArea";
import ChecksForm from "../../components/ChecksForm";
import UploadFile from "../../components/UploadFile";
import ListChainId from "./chains.json";
import bep20ABI from "./tokenabi.json";
import TableForm from "./table";
import { formatEther } from "@ethersproject/units";
import PQueue from "p-queue";
import Ads from "../../components/Ads";
import { ArrayXlsx } from "../../components/ArrayXlsx";

const queue = new PQueue({ concurrency: 10 });

const listRpc = [
  {
    label: "Choose Rpc",
    value: "choose",
  },
  {
    label: "ETH",
    value: "https://rpc.ankr.com/eth",
  },
  {
    label: "BSC 1",
    value: "https://bsc-dataseed4.defibit.io/",
  },
  {
    label: "BSC 2",
    value: "https://bsc-dataseed4.ninicoin.io/",
  },
  {
    label: "MATIC 1",
    value: "https://rpc-mainnet.maticvigil.com/",
  },
  {
    label: "MATIC 2",
    value: "https://polygon-rpc.com/",
  },
  {
    label: "MATIC 3",
    value: "https://rpc-mainnet.matic.quiknode.pro/",
  },
  {
    label: "FTM",
    value: "https://rpc.ftm.tools/",
  },
  {
    label: "Custom",
    value: "custom",
  },
];

const GetNameChainId = (id: any) => {
  let isi = JSON.parse(JSON.stringify(ListChainId));
  for (let i = 0; i <= isi.length; i++) {
    if (isi[i]) {
      if (Number(id) === Number(isi[i].chainId)) {
        return isi[i].chain;
      }
    }
  }
};

const ShowError = (error: any, url: string = "#") => {
  if (error.reason) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.reason,
      footer:
        '<a href="' + url + '" target="_blank">Why do I have this issue?</a>',
    });
  } else if (error.message) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
      footer:
        '<a href="' + url + '" target="_blank">Why do I have this issue?</a>',
    });
  } else {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong",
      footer:
        '<a href="' + url + '" target="_blank">Why do I have this issue?</a>',
    });
  }
};

export default function Scan() {
  const scroll: any = useRef();
  const [isCustomUrl, setIsCustomUrl] = useState(false);
  const [urlRpc, setUrlRpc] = useState("choose");
  const [tokenAddress, setTokenAddress] = useState("");
  const [listAddress, setListAddress] = useState("");
  const [zeroBalance, setZeroBalance] = useState(false);
  const [sameAddress, setsameAddress] = useState(false);
  const [isChainId, setIsChainId] = useState("");
  const [tokenName, setTokenName] = useState("");
  const [symbolToken, setSymbolToken] = useState("");
  const [decimalToken, setDecimalToken] = useState("");
  const [balanceAll, setBalanceAll] = useState<number>(0);
  const [resultScan, setResultScan] = useState<
    Array<{ address: string; balance: string; status: string }>
  >([]);
  const [erc20Address, setErc20Address] = useState("");
  useEffect(() => {
    document.title = "Batch Balance";
    HandelReloadTextInLocalStorage();
  }, []);

  const CheckConnect = async (url: string) => {
    try {
      let prov = new ethers.providers.JsonRpcProvider(url);
      const { chainId } = await prov.getNetwork();
      setIsChainId(chainId.toString());
      if (scroll.current) {
        scroll.current.scrollIntoView({ behavior: "smooth" });
      }
    } catch (error: any) {
      ShowError(error, "https://chainlist.org/");
    }
  };
  const CheckValidAddressContract = async (sc: string) => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(urlRpc);
      const tokens = new ethers.Contract(sc, bep20ABI, provider);
      const tname = await tokens.name();
      const tsymbol = await tokens.symbol();
      const decimal = await tokens.decimals();
      setTokenName(tname);
      setSymbolToken(tsymbol);
      setDecimalToken(decimal);
      if (scroll.current) {
        scroll.current.scrollIntoView({ behavior: "smooth" });
      }
    } catch (error: any) {
      ShowError(error, "https://links.ethers.org/v5-errors-CALL_EXCEPTION");
    }
  };

  const HandelUrlRpc = (e: any) => {
    setIsChainId("");
    setTokenName("");
    setSymbolToken("");
    setDecimalToken("");
    const { nodeName, value } = e.target;
    if (value === "custom" && nodeName === "SELECT") {
      setIsCustomUrl(true);
    } else if (value === "exit" && nodeName === "INPUT") {
      setIsCustomUrl(false);
    }
    setUrlRpc(value);
    const regexpression =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
    if (regexpression.test(value)) {
      setTimeout(() => {
        CheckConnect(value);
      }, 2000);
    }
  };
  const HandelTokenAddress = (e: any) => {
    setTokenName("");
    setSymbolToken("");
    setDecimalToken("");
    const { value } = e.target;
    setTokenAddress(value);
    if (ethers.utils.isAddress(value)) {
      setTimeout(() => {
        CheckValidAddressContract(value);
      }, 1000);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Invalid Address Smart Contract Tokens",
      });
    }
  };
  const HandelRemoveSameAddress = () => {
    let address = listAddress.trim();
    let address2 = address.split("\n");
    let address3 = Array.from(new Set(address2));
    let address4 = address3.join("\n");
    localStorage.setItem("FileWalletMulti", address4);
    setListAddress(address4);
  };
  const HandelListAddress = (e: any) => {
    const { value } = e.target;
    setListAddress(value);
    localStorage.setItem("FileWalletMulti", value);
  };
  const HandelZeroBalance = (e: any) => {
    setZeroBalance(e.target.checked);
  };
  const HandelsameAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    setsameAddress(e.target.checked);
    if (e.target.checked) {
      HandelRemoveSameAddress();
    }
  };
  const HandelReloadTextInLocalStorage = () => {
    const isi = localStorage.getItem("FileWalletMulti");
    if (isi) {
      setListAddress(isi);
    }
  };
  const HandelUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      e.preventDefault();
      if (!e.target.files) return;
      let reader: FileReader = new FileReader();
      reader.onload = async (event: Event) => {
        if (!reader.result) return;
        let tval = reader.result.toString();
        localStorage.setItem("FileWalletMulti", tval);
        HandelReloadTextInLocalStorage();
      };
      reader.readAsText(e.target.files[0]!);
    } catch (error: any) {
      ShowError(error);
    }
  };
  const ScanAddress = async () => {
    let tokens: any, decimal: any;
    setBalanceAll(0);
    setResultScan([]);
    let jumlah = 0;
    const address1 = listAddress.trim();
    const address2 = address1.split("\n");
    const provider = new ethers.providers.JsonRpcProvider(urlRpc);
    let list: Array<{ address: string; balance: string; status: string }> = [];
    const token = tokenAddress;
    if (token) {
      tokens = new ethers.Contract(token, bep20ABI, provider);
      decimal = await tokens.decimals();
    }
    address2.forEach(async (val: any, num: number) => {
      await queue.add(async () => {
        try {
          setErc20Address(val);
          let balance, blns;
          if (ethers.utils.getAddress(val)) {
            if (token) {
              balance = await tokens.balanceOf(val);
              blns = balance / Math.pow(10, decimal);
            } else {
              balance = await provider.getBalance(val);
              blns = formatEther(balance);
            }
            const success = {
              address: val,
              balance: blns.toString(),
              status: "success",
            };
            list.push(success);
            setResultScan(list);
            jumlah += Number(balance.toString());
            setBalanceAll(Number(jumlah));
          } else {
            const error = {
              address: val,
              balance: "0.00",
              status: "error",
            };
            list.push(error);
            setResultScan(list);
          }
        } catch (e) {
          const error = {
            address: val,
            balance: "0.0",
            status: "error",
          };
          list.push(error);
          setResultScan(list);
        }
      });
    });
  };
  const Download = () => {
    let nama = "Batch";
    if (tokenAddress && symbolToken) {
      nama = symbolToken;
    }
    ArrayXlsx(resultScan, nama);
  };
  return (
    <div className="row">
      <div className="col-sm-12 col-md-12">
        <div className="row g-2">
          <div className="col-md">
            {isCustomUrl === true ? (
              <FormInput
                handelForm={HandelUrlRpc}
                value={urlRpc}
                label="Custom Url Rpc"
              />
            ) : (
              <SelectForm
                list={listRpc}
                handelForm={HandelUrlRpc}
                value={urlRpc}
                label="List Rpc"
              />
            )}
          </div>
          <div className="col-md">
            <FormInput
              read={isChainId ? false : true}
              handelForm={HandelTokenAddress}
              value={tokenAddress}
              label="Address Token"
            />
          </div>
        </div>
        {isChainId && (
          <div className="text-center">
            <div className="d-grid gap-2 mx-auto">
              <button
                className="btn btn-outline-success"
                type="button"
                disabled={true}
              >
                Connect with : {GetNameChainId(isChainId)}
              </button>
              {tokenName && symbolToken && (
                <button
                  className="btn btn-outline-success"
                  type="button"
                  disabled={true}
                >
                  {tokenName}
                  {" - "}
                  {symbolToken}
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      {resultScan.length === 0 ||
      resultScan.length === listAddress.split("\n").length ? (
        <Ads />
      ) : (
        ""
      )}
      <div className="col-sm-12 col-md-12">
        <TextArea
          handelForm={HandelListAddress}
          value={listAddress}
          label={
            listAddress
              ? "List Address ( " +
                listAddress.split("\n").length.toString() +
                " )"
              : "List Address"
          }
        />
        <div className="row mt-4">
          <div className="col-sm-6 col-md-6">
            <UploadFile
              handelForm={HandelUploadFile}
              label="Uploud File List Address"
            />
          </div>
          <div className="col-sm-6 col-md-6">
            <ChecksForm
              handelForm={HandelsameAddress}
              value={sameAddress}
              label="Remove same Address"
            />
            <ChecksForm
              handelForm={HandelZeroBalance}
              value={zeroBalance}
              label="Hidden Zero Balance"
            />
          </div>
          {resultScan.length === 0 ||
          resultScan.length === listAddress.split("\n").length ? (
            <Ads />
          ) : (
            ""
          )}
          <div className="col-sm-12 col-md-12 my-1">
            <div className="d-grid gap-2">
              <button
                ref={scroll}
                onClick={ScanAddress}
                className={
                  isChainId && listAddress
                    ? "btn btn-success"
                    : "btn btn-danger"
                }
                type="button"
                disabled={
                  isChainId && listAddress
                    ? resultScan.length > 0
                      ? resultScan.length === listAddress.split("\n").length
                        ? false
                        : true
                      : false
                    : true
                }
              >
                {resultScan.length > 0
                  ? resultScan.length === listAddress.split("\n").length
                    ? "Completed!!!"
                    : "Loading!!!"
                  : "Scan.."}
              </button>
              <button
                onClick={Download}
                className="btn btn-primary"
                type="button"
                disabled={resultScan.length > 0 ? false : true}
              >
                Export xlsx
              </button>
            </div>
          </div>
          {resultScan.length > 0 && (
            <div className="col-sm-12 col-md-12 mt-5">
              <div className="d-grid gap-2 mx-auto text-center">
                <div>
                  <b>Total Balance</b>
                </div>
                <div>
                  {tokenAddress
                    ? Number(balanceAll) / Math.pow(10, Number(decimalToken))
                    : Number(balanceAll) / 1000000000000000000}
                  {" - "}
                  {tokenAddress ? symbolToken : GetNameChainId(isChainId)}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-sm-6 col-md-6 mx-auto my-1">
                  <div className="text-center">
                    <b>Progress</b>
                  </div>
                  <div className="progress my-2">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: `${
                          (
                            (Number(resultScan.length) /
                              Number(listAddress.split("\n").length)) *
                            100
                          ).toString() + "%"
                        }`,
                      }}
                      aria-valuenow={
                        (Number(resultScan.length) /
                          Number(listAddress.split("\n").length)) *
                        100
                      }
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      {(Number(resultScan.length) /
                        Number(listAddress.split("\n").length)) *
                        100}
                      {"%"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <p style={{ fontSize: "9px" }}>{erc20Address}</p>
              </div>
              <TableForm addressAll={resultScan} isBalance={zeroBalance} />
            </div>
          )}
          {resultScan.length === 0 ||
          resultScan.length === listAddress.split("\n").length ? (
            <Ads />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
