export default function Ads() {
  const randAds = () => {
    const list: Array<string> = [
      "https://niagaspace.sgp1.cdn.digitaloceanspaces.com/assets/images/affiliasi/banner/ads-persona-offline-to-online-business-cloud-hosting-affiliate-728-x-90.png",
      "https://niagaspace.sgp1.cdn.digitaloceanspaces.com/assets/images/affiliasi/banner/banner-banner-1645609226-728x90-1-.png",
      "https://niagaspace.sgp1.cdn.digitaloceanspaces.com/assets/images/affiliasi/banner/banner-banner-1645608293-728x90-1-.png",
      "https://niagaspace.sgp1.cdn.digitaloceanspaces.com/assets/images/affiliasi/banner/728-90-affiliate-starting-bisnis-online.png",
      "https://niagaspace.sgp1.cdn.digitaloceanspaces.com/assets/images/affiliasi/banner/campaign-juni-2020-cloud-hosting-affiliate-banner-728x90.png",
      "https://niagaspace.sgp1.cdn.digitaloceanspaces.com/assets/images/affiliasi/banner/banner-banner-1631863567-700x90.png",
    ];
    return list[Math.floor(Math.random() * list.length)];
  };
  const randAds2 = () => {
    const list: Array<string> = [
      "https://idcloudhost.com/wp-content/uploads/2017/01/IDCloudHost-SSD-Cloud-Hosting-Indonesia-970x250.jpg",
      "https://idcloudhost.com/wp-content/uploads/2017/01/970x250.png",
    ];
    return list[Math.floor(Math.random() * list.length)];
  };
  return (
    <div className="col-sm-12 col-md-12 my-3">
      <a
        href="https://panel.niagahoster.co.id/ref/176388"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={randAds().toString()}
          alt="Hosting Indonesia"
          style={{ border: "0", width: "100%", height: "90" }}
        />
      </a>
    </div>
  );
}
