import React, { useState, useEffect } from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import Swal from "sweetalert2";
import { ethers } from "ethers";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FormInput from "../../components/FormInput";
import TextArea from "../../components/TextArea";
import AbiToken from "./tokenabi.json";
import AbiSender from "./abiSender.json";
import ListChainId from "../erc20/chains.json";

function toFixed(x: any) {
  if (Math.abs(x) < 1.0) {
    let e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
}

const Injected = new InjectedConnector({
  supportedChainIds: [3, 137, 250, 56],
});

const ExampleAddress =
  "/** Example Format Address **/\n0x55d398326f99059fF775485246999027B3197955=0.001\n0x55d398326f99059fF775485246999027B3197955=0.1\n0x55d398326f99059fF775485246999027B3197955=1\n0x55d398326f99059fF775485246999027B3197955=1000\n/** Format Ethers not gwei/wei **/\nAddress=Amount";

const ShowError = (error: any, url: string = "#") => {
  if (error.reason) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.reason,
      footer:
        '<a href="' + url + '" target="_blank">Why do I have this issue?</a>',
    });
  } else if (error.message) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.message,
      footer:
        '<a href="' + url + '" target="_blank">Why do I have this issue?</a>',
    });
  } else {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong",
      footer:
        '<a href="' + url + '" target="_blank">Why do I have this issue?</a>',
    });
  }
};

export default function Connect() {
  const { activate, deactivate, error, active, account, chainId, library } =
    useWeb3React();
  const isUnsupportedChainIdError = error instanceof UnsupportedChainIdError;

  const addressSender = "0x0C9D218B8dA8F7Ff24742214B7bF885ABcA0A4e2";
  //const addressSender = "0x07cB2800e64e37036948b8283c7c62e82070d6Cc";
  const [balance, setBalance] = useState<number>(0);
  const [addressToken, setAddressToken] = useState("");
  const [multiAddress, setMultiAddress] = useState("");
  const [nameToken, setNameToken] = useState("");
  const [symbolToken, setSymbolToken] = useState("");
  const [decimalToken, setDecimalToken] = useState("");
  const [requestApprove, setRequestApprove] = useState(false);
  const [requestRemoveApprove, setRequestRemoveApprove] = useState(false);
  const [nameApprove, setNameApprove] = useState("Approve");
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [addressArray, setAddressArray] = useState<Array<string>>([]);
  const [amountArray, setAmountArray] = useState<Array<string>>([]);
  const [nameTx, setNameTx] = useState("Approve");
  const [txHash, setTxHash] = useState("");
  const HandleButtonConnect = async () => {
    try {
      await activate(Injected);
    } catch (e: any) {
      ShowError(e);
    }
  };
  const HandleButtonDisconnect = () => {
    try {
      deactivate();
    } catch (e: any) {
      ShowError(e);
    }
  };
  useEffect(() => {
    library?.getBalance(account).then((result: any) => {
      setBalance(result / 1e18);
    });
  }, [library]);
  const HandleAddressToken = (e: any) => {
    setNameToken("");
    setSymbolToken("");
    setDecimalToken("");
    const { value } = e.target;
    setAddressToken(value);
    if (ethers.utils.isAddress(value)) {
      CheckValidAddressToken(value);
    }
  };
  const HandelApprove = async () => {
    try {
      const tokens = new ethers.Contract(
        addressToken,
        AbiToken,
        library.getSigner()
      );
      setNameApprove("Wait Tx...");
      const trx = await tokens.approve(
        addressSender,
        "9999999999999999999999999999999999000000000000000000"
      );
      setNameApprove("Loading Tx...");
      await trx.wait();
      setRequestApprove(false);
      setRequestRemoveApprove(true);
      setNameApprove("Approve");
    } catch (e: any) {
      ShowError(e);
    }
  };
  const HandelRemoveApprove = async () => {
    try {
      const tokens = new ethers.Contract(
        addressToken,
        AbiToken,
        library.getSigner()
      );
      setNameApprove("Wait Tx...");
      const trx = await tokens.approve(addressSender, "0");
      setNameApprove("Loading Tx...");
      await trx.wait();
      setRequestRemoveApprove(false);
      setRequestApprove(true);
      setNameApprove("Complete...");
    } catch (e: any) {
      ShowError(e);
    }
  };
  const CheckValidAddressToken = async (address: string) => {
    try {
      const tokens = new ethers.Contract(address, AbiToken, library);
      const name = await tokens.name();
      const symbolToken = await tokens.symbol();
      const decimals = await tokens.decimals();

      setNameToken(name);
      setSymbolToken(symbolToken);
      setDecimalToken(decimals);

      const allow = await tokens.allowance(account, addressSender);
      if (allow.toString() === "0" || Number(allow) < 1) {
        setRequestApprove(true);
      } else {
        setRequestRemoveApprove(true);
      }
    } catch (e: any) {
      ShowError(e);
    }
  };
  const HandleFormMultiAddress = (e: any) => {
    const { value } = e.target;
    setMultiAddress(value);
    setTotalAmount(0);
    setAddressArray([]);
    setAmountArray([]);
  };
  const HandleValidMultiAddress = () => {
    let tempArray = multiAddress
      .trim()
      .valueOf()
      .split(/[\s,;=;:\t\r\n]+/);
    let oddArray = tempArray.filter((v, i) => i % 2);
    let evenArray = tempArray.filter((v, i) => !(i % 2));
    let addArray: Array<string> = [];
    let amtArray: Array<string> = [];
    if (oddArray.length !== evenArray.length) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Address and Amount not same!!!",
      });
      return;
    }
    let UNIT = 1000000000000000000;
    let jumlah = 0;
    for (let i = 0; i <= oddArray.length; i++) {
      if (evenArray[i] && oddArray[i]) {
        if (addressToken) {
          addArray.push(evenArray[i]);
          amtArray.push(toFixed(parseFloat(oddArray[i]) * UNIT).toString());
          jumlah += parseFloat(oddArray[i]);
        } else {
          addArray.push(evenArray[i]);
          amtArray.push((parseFloat(oddArray[i]) * UNIT).toString());
          jumlah += parseFloat(oddArray[i]);
        }
      }
    }
    setAddressArray(addArray);
    setAmountArray(amtArray);
    setTotalAmount(jumlah);
  };
  const HandleSendMulti = async () => {
    if (requestApprove) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You need to approve the token first!!!",
      });
      return;
    }
    let tokens = addressToken;
    try {
      if (totalAmount > 0 && addressArray.length === amountArray.length) {
        const UNIT = 1000000000000000000;
        const disperse = new ethers.Contract(
          addressSender,
          AbiSender,
          library.getSigner()
        );
        let gasPrice = await library.getGasPrice();
        gasPrice = ethers.utils.formatUnits(gasPrice, "gwei");
        let feePercent = await disperse.feePercent();
        let trx;
        if (tokens) {
          let gasLimit = await disperse.estimateGas.sendToken(
            addressToken,
            addressArray,
            amountArray,
            {
              from: account,
            }
          );
          let isfee =
            (Number(gasPrice) *
              Number(gasLimit.toString()) *
              Number(feePercent)) /
            100;
          isfee = isfee / 1000000000;
          isfee = isfee * UNIT;
          trx = await disperse.sendToken(
            addressToken,
            addressArray,
            amountArray,
            {
              value: isfee.toFixed(),
              from: account,
            }
          );
        } else {
          let gasLimit = await disperse.estimateGas.sendEth(
            addressArray,
            amountArray,
            {
              value: totalAmount * UNIT,
              from: account,
            }
          );
          let isfee =
            (Number(gasPrice) *
              Number(gasLimit.toNumber()) *
              Number(feePercent)) /
            100;
          isfee = isfee / 1000000000;
          let total = isfee + totalAmount;
          setNameTx("Loading Tx...");
          trx = await disperse.sendEth(addressArray, amountArray, {
            value: (total * UNIT).toFixed(),
            gasLimit: gasLimit.toString(),
          });
        }
        setTxHash(trx.hash);
        setNameTx("Wait Tx...");
        await trx.wait();
        setNameTx("Success!");
        Swal.fire({
          icon: "success",
          title: "Yeea!!!",
          text: "Success send Transaction!",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Address and Amount not same!!!",
        });
      }
    } catch (e: any) {
      ShowError(e);
    }
  };
  const GetNameChainId = (id: any) => {
    let isi = JSON.parse(JSON.stringify(ListChainId));
    for (let i = 0; i <= isi.length; i++) {
      if (isi[i]) {
        if (Number(id) === Number(isi[i].chainId)) {
          return isi[i].chain;
        }
      }
    }
    return id;
  };
  return (
    <React.Fragment>
      {active ? (
        <div className="col-sm-12 col-md-12 text-center">
          <div>ChainId : {GetNameChainId(chainId)}</div>
          <div>
            Address : {account?.substr(0, 7)}...
            {account?.substr(35, 42)}
          </div>
          <div>Balance : {balance}</div>
          <div className="d-grid gap-2 col-6 mx-auto mt-3">
            <button
              className="btn btn-danger btn-sm"
              onClick={HandleButtonDisconnect}
            >
              disconnect
            </button>
          </div>
        </div>
      ) : (
        <div className="col-sm-12 col-md-12">
          <div className="d-grid gap-2 col-6 mx-auto">
            <button className="btn btn-primary" onClick={HandleButtonConnect}>
              Connect
            </button>
          </div>
        </div>
      )}
      {/** Success Connect **/}
      {account && (
        <div className="col-sm-12 col-md-12 text-center mt-5">
          <div className="row">
            <div className="col-sm-6 col-md-6 mx-auto">
              <FormInput
                handelForm={HandleAddressToken}
                value={addressToken}
                label="Address Token"
              />
              {nameToken && (
                <React.Fragment>
                  <div className="my-3">
                    {nameToken}
                    {" - "}
                    {symbolToken}
                  </div>
                  {requestApprove && (
                    <button onClick={HandelApprove} className="btn btn-warning">
                      {nameApprove}
                    </button>
                  )}
                  {requestRemoveApprove && (
                    <button
                      onClick={HandelRemoveApprove}
                      className="btn btn-danger"
                    >
                      {nameApprove === "Approve"
                        ? "Remove Approve"
                        : nameApprove}
                    </button>
                  )}
                </React.Fragment>
              )}
              {/** Start Example **/}
              {/** End Example **/}
              <TextArea
                handelForm={HandleFormMultiAddress}
                value={multiAddress}
                label="List Address"
              />
              <div className="my-2">
                <button
                  onClick={() => setMultiAddress(ExampleAddress)}
                  type="button"
                  className="btn btn-info btn-sm mx-1"
                >
                  Example Format
                </button>
                <button
                  onClick={() => setMultiAddress("")}
                  type="button"
                  className="btn btn-warning btn-sm mx-1"
                >
                  Clear Address
                </button>
              </div>
              <div className="my-2">
                <button
                  onClick={HandleValidMultiAddress}
                  className="btn btn-success btn-sm"
                >
                  Check Address
                </button>
              </div>
              {totalAmount > 0 && addressArray.length > 0 ? (
                <div className="my-5">
                  <div>
                    Total Amount :{" "}
                    {totalAmount > 0 && (
                      <b>
                        {totalAmount} {symbolToken ? symbolToken : ""}
                      </b>
                    )}
                  </div>
                  <div>Total Address : {addressArray.length} </div>
                  <button
                    onClick={HandleSendMulti}
                    className="btn btn-primary btn-sm my-2"
                  >
                    {nameTx}
                  </button>
                  {txHash.length > 0 && (
                    <div className="mt-5 text-center">
                      <CopyToClipboard
                        text={txHash.toString()}
                        onCopy={() => Swal.fire("copy", txHash, "success")}
                      >
                        <span>
                          {txHash.substr(0, 15)}...{txHash.substr(51, 66)}
                        </span>
                      </CopyToClipboard>
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
      {/** Handle Erroe **/}
      {isUnsupportedChainIdError && (
        <div className="col-sm-12 col-md-12 text-center">
          <h2
            style={{
              top: "50%",
              fontFamily: "Montserrat, sans-serif",
              position: "relative",
              color: "#262626",
              textTransform: "uppercase",
              fontSize: "50px",
              letterSpacing: "3px",
            }}
          >
            Network not supported
          </h2>
        </div>
      )}
    </React.Fragment>
  );
}
