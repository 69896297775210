export default function Ads2() {
  const randAds = () => {
    const list: Array<string> = [
      "https://idcloudhost.com/wp-content/uploads/2017/01/IDCloudHost-SSD-Cloud-Hosting-Indonesia-970x250.jpg",
      "https://idcloudhost.com/wp-content/uploads/2017/01/970x250.png",
    ];
    return list[Math.floor(Math.random() * list.length)];
  };
  return (
    <div className="col-sm-12 col-md-12 my-3">
      <a
        href="https://my.idcloudhost.com/aff.php?aff=10153"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={randAds().toString()}
          alt="Hosting Indonesia"
          style={{ border: "0", width: "100%", height: "90" }}
        />
      </a>
    </div>
  );
}
