import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import Swal from "sweetalert2";

function ShowError(error: string) {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: error,
  });
}

export function ArrayXlsx(apiData: any, fileName: string) {
  const host = window.location.hostname;
  if (!apiData || apiData.length === 0) {
    ShowError("There is no data that can be exported");
    return;
  }
  let dataArray: Array<{ address: string; balance: string; status: string }> =
    [];
  let addressLength: Array<number> = [];
  let balanceLength: Array<number> = [];
  let statusLength: Array<number> = [];
  apiData.map((val: any) => {
    if (parseFloat(val.balance) > 0) {
      dataArray.push({
        address: val.address,
        balance: val.balance,
        status: val.status,
      });
    }
    addressLength.push(parseFloat(val.address.length));
    balanceLength.push(parseFloat(val.balance.length));
    statusLength.push(parseFloat(val.status.length));
    return !val;
  });
  if (dataArray.length === 0 || dataArray.length < 1) {
    ShowError("no address has a balance");
    return;
  }
  let maxAddress: number = Math.max(...addressLength);
  let maxBalance: number = Math.max(...balanceLength);
  let maxStatus: number = Math.max(...statusLength);
  maxAddress = Number(maxAddress) + 3;
  maxBalance = Number(maxBalance) + 3;
  maxStatus = Number(maxStatus) + 3;
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(dataArray);
  const wscols = [{ wch: maxAddress }, { wch: maxBalance }, { wch: maxStatus }];
  /** ws["!ref"] =  "A" + dataArray.length.toString() + ":C" + dataArray.length.toString(); **/
  ws["!cols"] = wscols;
  const wb = { Sheets: { address: ws }, SheetNames: ["address"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  fileName =
    "(" +
    host.toString() +
    ") " +
    fileName +
    " " +
    Math.floor(Date.now() / 1000).toString();
  FileSaver.saveAs(data, fileName + fileExtension);
}
